import { Injectable } from '@angular/core';
import { Router, NavigationEnd, Route } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbService {
  // Observable para controlar la visibilidad del breadcrumb
  private breadcrumbVisible = new BehaviorSubject<boolean>(true);
  private showGrid = new BehaviorSubject<boolean>(false);

  constructor(private router: Router) {
    // Nos suscribimos a los eventos de navegación para detectar cambios en la ruta
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        this.checkRoute(this.router.url);
      });
  }

  // Método que revisa la ruta actual y decide si mostrar o no el breadcrumb
  private checkRoute(url: string) {
    const hiddenRoutes = ['/', '/dashboard', '/login'];  // Rutas donde se ocultará el breadcrumb
    const shouldShowBreadcrumb = !hiddenRoutes.includes(url);
    this.breadcrumbVisible.next(shouldShowBreadcrumb);
    if(this.router.url != '/admin-console'){
      this.showGrid.next(false);
    }else{
      this.showGrid.next(true);
    }
  }

  // Método para obtener el estado actual del breadcrumb
  isBreadcrumbVisible() {
    return this.breadcrumbVisible.asObservable();
  }

  // Método para mostrar o no grid en rutas hijas
  isGridcrumbVisible() {
    return this.showGrid.asObservable();
  }
}
